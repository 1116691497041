<template>
  <el-cascader
    ref="cascader"
    popper-class="el-cascader__custom-task"
    style="display: block;"
    :options="treeData"
    :value="value"
    :props="{ label: 'name', value: 'id', multiple: false, checkStrictly: true, emitPath: false, expandTrigger: 'hover' }"
    :show-all-levels="false"
    clearable
    filterable
    placeholder="请选择任务目录分类"
    @change="onChange"
  >
    <template slot-scope="{ data }">
      <div @click.stop.prevent="onSelect(data)">{{ data.name }}</div>
    </template>
  </el-cascader>
</template>
<script>
import { getTaskCatelogList } from '@/api/task'
import { listToTree, treeForEach } from '@/utils/tree'

export default {
  props: {
    value: [String, Number, Array],
  },
  data() {
    return {
      treeData: [],
    }
  },
  created() {
    this.fetchTree()
  },
  methods: {
    fetchTree() {
      getTaskCatelogList().then(res => {
        const tree = listToTree(res, { id: 'id', parentId: 'parent_id', children: 'children' })
        treeForEach(tree, node => {
          if (node.children?.length === 0) {
            delete node.children
          }
        })
        this.treeData = tree
      })
    },
    onChange(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
    onSelect(data) {
      this.$emit('input', data.id)
      this.$emit('change', data.id)
    },
  },
}
</script>
<style lang="scss">
.el-cascader__custom-task {
  .el-radio {
    display: none;
  }

  .el-cascader-node.in-active-path,
  .el-cascader-node.is-selectable.in-checked-path {
    font-weight: 400;
    color: inherit;
  }

  .el-cascader-node.is-selectable.in-checked-path.is-active {
    font-weight: 600;
    color: $color-primary;
  }
}
</style>
