<!--
 * @Author: jiang
 * @Date: 2021-07-12 11:40:28
 * @Description:
-->
<template>
  <el-cascader
    ref="cascader"
    :popper-class="multiple ? 'el-cascader__custom-dept-multiple' : 'el-cascader__custom-dept-single'"
    style="display: block;"
    :options="deptTree"
    :value="value"
    :props="{ label: 'name', value: 'id', multiple: multiple, checkStrictly: true, emitPath: false, expandTrigger: 'hover' }"
    :show-all-levels="!multiple"
    clearable
    filterable
    @change="onChange"
  >
    <template slot-scope="{ node, data }">
      <span style="display: inline-flex; width: 100%;">
        <span v-if="data.itemType === 'action'">
          <span
            class="btn-confirm"
            @click="onSelectAll(node, true)"
          >全选</span>
          <span
            class="btn-cancel"
            @click="onSelectAll(node, false)"
          >取消</span>
        </span>
        <span
          v-else
          style="flex: 1;"
          @click.stop="onSelect(data)"
        >{{ data.name }}</span>
      </span>
    </template>
  </el-cascader>
</template>
<script>
import { getDeptTree } from '@/api/user'

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    value: [String, Number, Array],
  },
  data() {
    return {
      deptTree: [],
      checked: false,
    }
  },
  created() {
    this.fetchDetpTree()
  },
  methods: {
    fetchDetpTree() {
      getDeptTree().then(res => {
        this.deptTree = this.multiple ? this.handlerFormat([res]) : res.children
      })
    },
    handlerFormat(list) {
      let deep = 0
      function treeForEach(tree, func) {
        const cDeep = deep++
        tree.forEach(node => {
          func(node, cDeep)

          node.children && treeForEach(node.children, func)
        })
      }

      treeForEach(list, (node, deep) => {
        if (node.itemType === 'action') return
        if (deep <= 1) {
          node.disabled = true
        }

        if (node.children?.length && deep > 0) {
          node.children.unshift({
            id: `action_${node.id}`,
            name: 'action',
            itemType: 'action',
            disabled: true,
          })
        }
      })
      return list[0].children
    },
    handlerEmit(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
    onChange(value) {
      this.handlerEmit(value)
    },
    onSelect(data) {
      if (this.multiple) {
        const index = this.value.findIndex(item => item === data.id)
        let value
        if (index > -1) {
          value = [...this.value]
          value.splice(index, 1)
        } else {
          value = [...this.value, data.id]
        }

        this.handlerEmit(value)
      } else {
        this.handlerEmit(data.id)
      }
    },
    onSelectAll(node, flag) {
      const value = [...this.value]
      if (node.parent === null) {
        this.deptTree.forEach(item => {
          if (item.itemType !== 'action') {
            const id = item.id
            const index = value.findIndex(item => item === id)
            if (flag && index === -1) {
              value.push(id)
            } else if (!flag && index > -1) {
              value.splice(index, 1)
            }
          }
        })
      } else {
        node.parent.children.forEach(item => {
          if (item.data.itemType !== 'action') {
            const id = item.data.id
            const index = value.findIndex(item => item === id)
            if (flag && index === -1) {
              value.push(id)
            } else if (!flag && index > -1) {
              value.splice(index, 1)
            }
          }
        })
      }

      this.handlerEmit(value)
    },
  },
}
</script>

<style lang="scss">
.el-cascader__custom-dept-single {
  .el-radio {
    display: none;
  }

  .el-cascader-node.in-active-path,
  .el-cascader-node.is-selectable.in-checked-path {
    font-weight: 400;
    color: inherit;
  }

  .el-cascader-node.is-selectable.in-checked-path.is-active {
    font-weight: 600;
    color: $color-primary;
  }
}

.el-cascader__custom-dept-multiple {
  .btn-confirm,
  .btn-cancel {
    padding: 3px 10px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    color: rgba($color: $color-primary, $alpha: 0.6);
    border: 1px solid rgba($color: $color-primary, $alpha: 0.6);
    border-radius: 4px;

    &:hover {
      color: $color-primary;
      border-color: rgba($color: $color-primary, $alpha: 0.6);
    }
  }

  .el-checkbox.is-disabled {
    display: none;
  }
}
</style>
