/*
 * @Author: jiang
 * @Date: 2021-06-11 19:08:32
 * @Description: 人员列表
 */

import request from '../axios'

/**
 * 部门列表
 * @param { string } [id]
 */
export function getDeptTree(id = '') {
  return request({
    url: `/api/departments/${id}`,
  }).then(res => {
    const list = [...res.children]
    let node = list.shift()
    while (node) {
      if (node.children && node.children.length === 0) {
        delete node.children
      }
      node.children && list.push(...node.children)
      node = list.shift()
    }
    return res
  })
}

/**
 * 新增部门分类
 * @param { object } params
 * @param { string } params.name
 * @param { string } params.parent_id
 */
export function createDept(params) {
  return request({
    method: 'post',
    url: '/api/departments/',
    data: params,
  })
}

/**
 * 编辑部门分类
 */
export function updateDept(id, params) {
  return request({
    method: 'post',
    url: `/api/departments/${id}`,
    data: params,
  })
}

/**
 * 编辑部门分类
 */
export function deleteDept(id) {
  return request({
    method: 'post',
    url: `/api/departments/del/${id}`,
  })
}

/**
 * 分页查询用户
 * @param { object } params
 */
export function getUserPage(params) {
  // const id = params.id
  // if (id) {
  //   return request({
  //     url: `/api/departments/${id}/users`,
  //     params,
  //   })
  // } else {
  //   return request({
  //     url: '/api/users',
  //     params,
  //   })
  // }
  return request({
    url: '/api/users/list',
    params,
  })
}

/**
 * 查询人员列表
 * @param { object } params
 * @param { string } params.department_id
 * @param { string } params.phone
 * @param { string } params.name
 */
export function getUserList(params) {
  return request({
    url: '/api/users/all',
    params,
  })
}

/**
 * 查询部门及子部门的用户
 */
export function getDeptUserTree(id) {
  return request({
    url: `/api/departments/${id}/users/tree`,
  })
}

/**
 * 用户详情
 */
export function getUser(id) {
  return request({
    url: `/api/users/${id}`,
  })
}

/**
 * 创建用户
 */
export function createUser(params) {
  return request({
    method: 'post',
    url: '/api/users/add',
    data: params,
  })
}

/**
 * 修改用户信息
 */
export function updateUser(id, params) {
  return request({
    method: 'post',
    url: `/api/users/information/${id}`,
    data: params,
  })
}

/**
 * 修改用户为部门管理员
 * @param { string } id - 用户id
 * @param { boolean } flag - true 设为部门管理员
 */
export function updateUserToAdmin(id, flag) {
  return request({
    method: 'post',
    url: '/api/users/department/admin',
    data: {
      user_id: id,
      type: flag ? 'set' : 'cancel',
    },
  })
}

/**
 * 人员考勤列表分页查询
 * @param { obejct } params
 * @param { string } params.department_id - 部门id
 * @param { string } params.type - 类型标识（diaoxun：调训，ligang：离岗，waipin：外聘，leave：请假，abroad：出国）
 * @param { string } params.primary_department_name - 原单位名称
 */
export function getUserAttendancePage(params) {
  return request({
    url: '/api/attendances/list',
    params,
  })
}

/**
 * 创建人员考勤
 * @param { obejct } params
 * @param { string } params.department_id - 部门id
 * @param { string } params.target_user_name - 人员姓名
 * @param { string } params.primary_department_name - 原单位名称
 * @param { string } params.type - 类型标识（diaoxun：调训，ligang：离岗，waipin：外聘，leave：请假，abroad：出国）
 * @param { string } params.start_time - 起始时间
 * @param { string } params.end_time - 结束时间(长期传递参数为0)
 * @param { string } params.leave_reason - 请假事由
 * @param { string } params.destruction_leave_date - 销假时间
 */
export function createUserAttendance(params) {
  return request({
    method: 'post',
    url: '/api/attendance',
    data: params,
  })
}

/**
 * 更新人员考勤
 * @param { string } id -
 * @param { obejct } params
 */
export function updateUserAttendance(id, params) {
  return request({
    method: 'post',
    url: `/api/attendance/${id}`,
    data: params,
  })
}

/**
 * 删除人员考勤
 * @param { string } id -
 */
export function deleteUserAttendance(id) {
  return request({
    method: 'post',
    url: `/api/attendance/del/${id}`,
  })
}
