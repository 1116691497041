<template>
  <el-cascader
    ref="cascader"
    :popper-class="multiple ? 'el-cascader__custom-user-multiple' : 'el-cascader__custom-user-single'"
    style="display: block;"
    :options="deptTree"
    :value="value"
    :props="{
      label: 'name',
      value: 'id',
      multiple: multiple,
      checkStrictly: true,
      emitPath: false,
      expandTrigger: 'hover',
    }"
    :show-all-levels="false"
    clearable
    filterable
    @change="onChange"
  >
    <template slot-scope="{ node, data }">
      <span style="display: inline-flex; align-items: center; width: 100%;">
        <span v-if="data.itemType === 'action'">
          <span class="btn-confirm" @click="onSelectAll(node, true)">全选</span>
          <span class="btn-cancel" @click="onSelectAll(node, false)">取消</span>
        </span>
        <template v-else>
          <span v-if="data.itemType === 'user'" class="el-icon-user g-mr-5"></span>
          <span style="flex: 1;" @click.stop="onSelect(data)">{{ data.name }}</span>
        </template>
      </span>
    </template>
  </el-cascader>
</template>
<script>
import { getDeptUserTree } from '@/api/user'
export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    value: [String, Number, Array],
    scope: {
      type: String,
    },
  },
  data() {
    return {
      deptTree: [],
    }
  },
  computed: {
    deptId() {
      if (this.scope === 'all') {
        return 1
      }

      const deptPid = this.$store.state.user.userInfo.department.parent_id
      const chuIds = [2, 31, 39]

      if (chuIds.includes(deptPid)) {
        return this.$store.getters['user/deptId']
      }

      return deptPid
    },
  },
  created() {
    this.fetchUserList()
  },
  methods: {
    fetchUserList() {
      getDeptUserTree(this.deptId).then(res => {
        this.deptTree = this.format([res])
      })
    },
    format(list) {
      function treeForEach(tree, func) {
        tree.forEach(node => {
          func(node)

          node.children && treeForEach(node.children, func)
        })
      }

      treeForEach(list, node => {
        if (node.itemType === 'user' || node.itemType === 'action') return

        node.id = `dept_${node.id}`
        node.parent_id = `dept_${node.parent_id}`
        node.disabled = true

        node.users.forEach(item => {
          item.itemType = 'user'
        })

        if (node.users.length) {
          node.children.push(...node.users)
          if (this.multiple) {
            node.children.unshift({
              id: `action_${node.id}`,
              name: 'action',
              itemType: 'action',
              disabled: true,
            })
          }
        }
      })

      return list[0].children
    },
    handlerEmit(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
    onChange(value) {
      this.handlerEmit(value)
    },
    onSelect(data) {
      // console.log(data)
      if (data.itemType !== 'user') return
      if (this.multiple) {
        const index = this.value.findIndex(item => item === data.id)
        let value
        if (index > -1) {
          value = [...this.value]
          value.splice(index, 1)
        } else {
          value = [...this.value, data.id]
        }

        this.handlerEmit(value)
      } else {
        this.handlerEmit(data.id)
      }
    },
    onSelectAll(node, flag) {
      const value = [...this.value]
      if (node.parent === null) {
        this.deptTree.forEach(item => {
          if (item.itemType === 'user') {
            const id = item.id
            const index = value.findIndex(item => item === id)
            if (flag && index === -1) {
              value.push(id)
            } else if (!flag && index > -1) {
              value.splice(index, 1)
            }
          }
        })
      } else {
        node.parent.children.forEach(item => {
          if (item.data.itemType === 'user') {
            const id = item.data.id
            const index = value.findIndex(item => item === id)
            if (flag && index === -1) {
              value.push(id)
            } else if (!flag && index > -1) {
              value.splice(index, 1)
            }
          }
        })
      }
      this.handlerEmit(value)
    },
  },
}
</script>

<style lang="scss">
.el-cascader__custom-user-single {
  .el-radio {
    display: none;
  }

  .el-cascader-node.in-active-path,
  .el-cascader-node.is-selectable.in-checked-path {
    font-weight: 400;
    color: inherit;
  }

  .el-cascader-node.is-selectable.in-checked-path.is-active {
    font-weight: 600;
    color: $color-primary;
  }
}

.el-cascader__custom-user-multiple {
  .btn-confirm,
  .btn-cancel {
    padding: 3px 10px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    color: rgba($color: $color-primary, $alpha: 0.6);
    border: 1px solid rgba($color: $color-primary, $alpha: 0.6);
    border-radius: 4px;

    &:hover {
      color: $color-primary;
      border-color: rgba($color: $color-primary, $alpha: 0.6);
    }
  }

  .el-checkbox.is-disabled {
    display: none;
  }
}
</style>
